import instance from './instance'
import instance2 from './instance2'
import {
	PageRequest,
	CmmnCodeRequestType,
	BoardRequestType,
	EmployeeRequestType,
	InspectionResultListType,
	InspectionResultDetailType,
	PrevReceptionItemListType,
	CovidInspectionResultSearchType,
	PrevReceptionSearchType,
	userInfoStateProps,
	UserInfoUpdateType,
	InfoClientSearch,
	InfoClientSearchForInternal,
	DiseaseTypes,
	employeeSearchType,
	PrevReceptionChartTypes,
} from 'constants/types'
import { GridRowId, GridValidRowModel } from '@mui/x-data-grid-premium'
import fileDownload from 'js-file-download'

/**
 * 토큰으로 사용자 정보 확인
 * @returns
 */
export const apiGetMe = async () => {
	return await instance.get('/user/me').then((result) => result.data)
}

/**
 * =====================================================
 * 환경설정
 * 1. 메뉴 목록
 * 2. 공통코드
 * 3. 환경설정
 * 4. 에디터 이미지
 * 5. 게시판
 * 6. 첨부파일
 * 7. 대시보드
 * 8. 개인정보 변경
 * 101. 거래처 계정관리
 * 102. 검사결과
 * 103. 코로나 검사결과
 * 104. 검사항목조회
 * 105. 검사결과 연동
 * 106. 우편 결과지
 * 107. SMS
 * 108. Slide/Block 외부반출 신청
 * =====================================================
 */

// ---------------------- 메뉴 목록 시작 ------------------
/**
 * 메뉴 목록 호출
 * @returns
 */
export const apiGetMenuList = async () => {
	return await instance.get('/menu/list').then((result) => result.data)
}
// ---------------------- 메뉴 목록 끝 -------------------

// ---------------------- 공통 코드 시작 ------------------

/**
 * 1래벨 공통코드 호출
 * @returns
 */
export const apiGetCmmnCodeParent = async () => {
	return await instance2.get('/cmmn/list/parent').then((result) => result.data)
}

/**
 * parentId로 2래벨 공통코드 호출
 */
export const apiGetCmmnCodeLevel2 = async (parentId: number) => {
	return await instance2
		.get(`/cmmn/list/second/${parentId}`)
		.then((result) => result.data)
}

/**
 * parentId로 3래벨 공통코드 호출
 */
export const apiGetCmmnCodeLevel3 = async (parentId: number) => {
	return await instance2
		.get(`/cmmn/list/third/${parentId}`)
		.then((result) => result.data)
}

/**
 * cmmnCd로 2래벨 코드 호출
 * @param {*} cmmnCd
 */
export const apiGetCmmnCodeLevel2ByParentCmmnCd = async (
	cmmnCd: string,
	level: number
) => {
	const parentId = await instance2.get(`/cmmn/info/${cmmnCd}`).then((res) => {
		return res.data.id
	})
	if (parentId) {
		return await instance2
			.get(`/cmmn/list/cmmnResponse/${parentId}/${level}`)
			.then((res) => res.data)
	}
}

/**
 * 공통코드 저장
 * @param {*} cmmnCodeRequest
 * @returns
 */
export const apiSaveCmmnCode = async (cmmnCodeRequest: CmmnCodeRequestType) => {
	// id가 있으면 수정
	console.log(cmmnCodeRequest)
	if (cmmnCodeRequest.id) {
		console.log('update')
		return await instance2
			.put('/cmmn/save', cmmnCodeRequest)
			.then((result) => result.data)
	} else {
		console.log('save')
		return await instance2
			.post('/cmmn/save', cmmnCodeRequest)
			.then((result) => result.data)
	}
}

/**
 * 공통코드 삭제
 * 삭제 시도 시 자식 요소가 존재할 경우 exception
 * @param id
 * @returns
 */
export const apiDeleteCmmnCode = async (id: number) => {
	return await instance2.delete(`/cmmn/delete/${id}`).then((res) => res.data)
}

/**
 * 공통코드 전체 목록 호출
 * @returns
 */
export const apiCmmnCdList = async () => {
	return await instance2.get('/cmmn/codeList').then((res) => res.data)
}

// ---------------------- 공통 코드 끝 -------------------

// ---------------------- 회원 API 시작 ------------------

/**
 * 회원 목록
 * @param {
 *  page: number,
 *  size: number
 * } pageRequest
 * @returns
 */
export const apiGetUserList = async (pageRequest: PageRequest) => {
	return await instance
		.get('/user/list', {
			params: {
				...pageRequest,
			},
		})
		.then((result) => result.data)
}

// ---------------------- 회원 API 끝 ------------------

// ---------------------- 에디터 이미지 API 시작 ------------------

/**
 * 에디터 이미지 첨부
 * @param file
 * @returns
 */
export const editorImageUpload = async (file: File) => {
	const data = new FormData()
	data.append('file', file)
	return await instance2
		.post('/file/editor/image/save', data)
		.then((result) => result.data)
}

// ---------------------- 에디터 이미지 API 끝 ------------------

// ---------------------- 게시판 API 시작 ------------------

/**
 * 게시물 목록
 * @param searchKeyword
 * @returns
 */
export const boardList = async (
	searchKeyword: String,
	infoType: string,
	searchDisplayTo: string
) => {
	return await instance2
		.get('/board/list', {
			params: {
				searchKeyword,
				displayTo: infoType,
				searchDisplayTo,
			},
		})
		.then((result) => result.data)
}

/**
 * 게시물 저장
 * @param boardRequest
 * @returns
 */
export const boardSave = async (boardRequest: BoardRequestType) => {
	console.log(boardRequest)
	return await instance2
		.post('/board/save', boardRequest)
		.then((result) => result.data)
}

/**
 * 게시물 보기
 * @param boardId
 * @returns
 */
export const boardView = async (boardId: string, infoType: string) => {
	return await instance2
		.get(`/board/view/${boardId}`, {
			params: {
				displayTo: infoType,
			},
		})
		.then((result) => result.data)
}

/**
 * 게시물 삭제
 * @param boardId
 * @returns
 */
export const boardDelete = async (boardId: string, attachFileId: string) => {
	return await instance2
		.delete(`/board/delete/${boardId}/${attachFileId}`)
		.then((res) => res.data)
}

/**
 * 로그인 시 팝업 조회
 * @param infoType
 * @returns
 */
export const boardPopupList = async (infoType: string) => {
	return await instance
		.get('/board/popupList', {
			params: {
				displayTo: infoType,
			},
		})
		.then((result) => result.data)
}

// ---------------------- 게시판 API 끝 ------------------

// ---------------------- 파일 API 시작 ------------------

/**
 * 첨부파일 마스터 ID
 */
export const fileMasterId = async () => {
	return await instance2.get('/file/get/id').then((res) => res.data)
}

/**
 * attachFileId 로 파일 목록 찾기 (마스터 테이블 조회)
 * @param attachFileId
 */
export const attachFileList = async (attachFileId: string) => {
	return await instance2
		.get(`/file/list`, {
			params: {
				attachFileId,
			},
		})
		.then((res) => res.data)
}

/**
 * 파일 업로드
 * @param {*} files
 */
export const fileUpload = async (
	files: File[],
	attachFileId: string,
	fileDest: string
) => {
	// 저장된 파일명을 담을 배열
	const returnArr = []

	for (let i = 0; i < files.length; i++) {
		const formData = new FormData()
		formData.append('file', files[i])
		formData.append('fileDest', fileDest)
		formData.append('attachFileId', attachFileId)

		const { data } = await instance2.post(`/file/save`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
		returnArr.push(data)
	}

	return returnArr
}

/**
 * 파일 삭제
 * @param {
 * attachFileId,
 * fileId
 * } fileDeleteRequest
 */
export const fileDelete = async (attachFileId: string, fileId: number) => {
	return instance2
		.delete(`/file/delete/${attachFileId}/${fileId}`)
		.then((res) => res.data)
}

/**
 * 첨부파일 다운로드 by fileId
 * @param fileId
 * @param orgFileName
 * @returns
 */
export const fileDownloadByFileId = async (
	fileId: number,
	orgFileName: string
) => {
	return instance2
		.get(`/file/view/id/${fileId}`, {
			responseType: 'blob',
		})
		.then((res) => {
			fileDownload(res.data, orgFileName)
		})
}

/**
 * 첨부파일 다운로드 by fileName (rename되어 DB에 저장된 파일명)
 * @param fileName
 * @param orgFileName
 * @returns
 */
export const fileDownloadByFileName = async (
	fileName: string,
	orgFileName: string
) => {
	return instance2
		.get(`/file/view/name/${fileName}`, {
			responseType: 'blob',
		})
		.then((res) => {
			fileDownload(res.data, orgFileName)
		})
}

/**
 * 리포트용 첨부파일 목록
 * @param parentId
 */
export const reportFileList = async (
	parentId: number,
	searchKeyword: string
) => {
	console.log(parentId, searchKeyword)
	return instance2
		.get(`/file/reportFileList`, {
			params: {
				parentId,
				searchKeyword,
			},
		})
		.then((res) => res.data)
}

/**
 * session clientID로 파일 찾기
 * @returns
 */
export const reportFileByClientID = async () => {
	return await instance.get(`/file/view/reportFile`).then((res) => res.data)
}
// ---------------------- 파일 API 끝 ------------------

// ---------------------- 대시보드 API 시작 ------------------

/**
 * 대시보드 데이터 세트
 * @returns
 */
export const dashboardData = async (infoType: string) => {
	return await instance
		.get('/dashboard/get/data', {
			params: {
				displayTo: infoType,
			},
		})
		.then((res) => res.data)
}

// ---------------------- 대시보드 API 끝 ------------------

// ---------------------- 개인정보 API 시작 ------------------

/**
 * 개인정보 비밀번호 확인
 * @returns
 */
export const confirmPassword = async (request: userInfoStateProps) => {
	return await instance
		.post('/user/confirmPassword', request)
		.then((res) => res.data)
}

/**
 * 개인정보 수정
 * @param userInfoRequest
 * @returns
 */
export const updateUserData = async (userInfoRequest: UserInfoUpdateType) => {
	return await instance2
		.put('/user/updateInfo', userInfoRequest)
		.then((res) => res.data)
}

// ---------------------- 개인정보 API 끝 ------------------

// ---------------------- 거래처 계정관리 API 시작 ------------------

/**
 * 거래처 계정 목록
 * @param searchRequest
 * @returns
 */
export const employeeList = async (searchRequest: employeeSearchType) => {
	return await instance
		.get('/employee/list', {
			params: {
				...searchRequest,
			},
		})
		.then((result) => result.data)
}

/**
 * 거래처 직원 아이디 중복 검사
 * @param userID
 * @returns
 */
export const employeeCheck = async (userID: string) => {
	return await instance2
		.get(`/employee/idCheck/${userID}`)
		.then((res) => res.data)
}

/**
 * 거래처 계정 저장
 * @param employeeRequest
 * @returns
 */
export const employeeSave = async (employeeRequest: EmployeeRequestType) => {
	return await instance2
		.post('/employee/save', employeeRequest)
		.then((result) => result.data)
}

/**
 * 거래처 계정 수정
 * @param employeeRequests
 * @returns
 */
export const employeeUpdate = async (employeeRequests: GridValidRowModel[]) => {
	console.log(employeeRequests)
	return await instance2
		.put('/employee/save', employeeRequests)
		.then((result) => result.data)
}

/**
 * 거래처 계정 삭제
 * @param ids
 * @returns
 */
export const employeeDelete = async (ids: GridRowId[]) => {
	if (ids.length === 1) {
		return await instance2
			.delete(`/employee/delete/${ids[0]}`)
			.then((result) => result.data)
	} else if (ids.length > 1) {
		return await instance2
			.post('/employee/delete', ids)
			.then((result) => result.data)
	} else {
		return false
	}
}

// ---------------------- 거래처 계정관리 API 끝 ------------------

// ---------------------- 검사결과 API 시작 ------------------

/**
 * 내부 사용자 계정으로 대리점 목록 조회
 * @returns
 */
export const clientListBySystemUserInfo = async (
	request: InfoClientSearchForInternal
) => {
	return await instance
		.get('/test/clientList', { params: { ...request } })
		.then((result) => result.data)
}

/**
 * 검사결과 수진자 리스트
 * @returns
 */
export const receptionList = async (request: InspectionResultListType) => {
	return await instance
		.get('/test/receptionList', { params: { ...request } })
		.then((result) => result.data)
}

/**
 * 검사결과 수진자 리스트 V2
 * @returns
 */
export const receptionListV2 = async (request: any) => {
	return await instance
		.get('/test/receptionListV2', { params: { ...request } })
		.then((result) => result.data)
}

/**
 * 검사결과 상세
 */
export const receptionDetail = async (request: InspectionResultDetailType) => {
	return await instance
		.get('/test/receptionDetail', { params: { ...request } })
		.then((result) => result.data)
}

/**
 * 검사결과 상세 결과조회 V1.1 전용
 */
export const receptionDetailV11 = async (
	request: InspectionResultDetailType
) => {
	return await instance
		.get('/test/receptionDetailV11', { params: { ...request } })
		.then((result) => result.data)
}

/**
 * 검사결과 상세 결과조회 V2 전용
 */
export const receptionDetailV2 = async (
	request: InspectionResultDetailType
) => {
	return await instance
		.get('/test/receptionDetailV2', { params: { ...request } })
		.then((result) => result.data)
}

/**
 * 이전 검사결과 목록 (팝업 출력)
 * @param request
 * @returns
 */
export const prevReceptionItemList = async (
	request: PrevReceptionItemListType
) => {
	return await instance
		.get('/test/prevReceptionItemList', { params: { ...request } })
		.then((result) => result.data)
}

/**
 * 검사정보 (팝업)
 * @param RItemID
 * @returns
 */
export const testInfoSelect = async (RItemID: number) => {
	return await instance
		.get(`/test/testInfoSelect`, {
			params: {
				RItemID,
			},
		})
		.then((result) => result.data)
}

/**
 * 리포트 이미지 출력용 JSON 데이터
 * @param request
 * @returns
 */
export const reportJSON = async (request: any) => {
	return await instance
		.get('/report/imageData', {
			params: {
				...request,
			},
		})
		.then((result) => result.data)
}

/**
 * HTML로 제작된 리포트 출력용 JSON 데이터이며, 지금은 사용 안함... 확인 후 삭제 예정
 * @param request
 * @returns
 */
export const reportData = async (request: any) => {
	return await instance
		.get('/report/reportData', {
			params: {
				...request,
			},
		})
		.then((result) => result.data)
}

export const reportText = async (request: any) => {
	return await instance
		.get('/report/reportText', {
			params: {
				...request,
			},
		})
		.then((result) => result.data)
}

/**
 * 결과 이미지 목록
 * receptionID, clientID, rItemID (optional)
 * @param request
 * @returns
 */
export const testImageList = async (request: any) => {
	return await instance
		.get('/test/testImageList', {
			params: {
				...request,
			},
		})
		.then((result) => result.data)
}

/**
 * 접수 열람 상태 변경
 * receptionID, clientID
 * @param request
 * @returns
 */
export const receptionView = async (request: any) => {
	return await instance2
		.post(`/test/receptionView`, request)
		.then((result) => result.data)
}

/**
 * 병원체 신고현황 조회
 * @param request
 * @returns
 */
export const diseaseList = async (request: DiseaseTypes) => {
	return await instance
		.get('/test/diseaseList', {
			params: {
				...request,
			},
		})
		.then((result) => result.data)
}

/**
 * 병원체 신고현황 엑셀 다운로드
 * @param request
 * @returns
 */
export const diseaseExcel = async (request: DiseaseTypes) => {
	return await instance
		.get('/test/diseaseExcel', {
			params: {
				...request,
			},
		})
		.then((result) => result.data)
}

/**
 * 이전 결과 - 이전 결과 팝업 그래프 출력용 데이터 조회
 * @param request
 * @returns
 */
export const prevReceptionChartData = async (
	request: PrevReceptionChartTypes
) => {
	return await instance
		.get('/test/prevReceptionChartData', {
			params: {
				...request,
			},
		})
		.then((result) => result.data)
}

/**
 * 결과 조회 2.0에서 수진자명 정보로 목록 조회
 * @param request
 * @returns
 */
export const apiReceptionSelectByPatientInfoV2 = async (request: any) => {
	return await instance
		.get('/test/receptionSelectByPatientInfoV2', {
			params: {
				...request,
			},
		})
		.then((result) => result.data)
}

/**
 * 결과 조회 2.0에서 수진자명 정보로 검사 목록 조회
 * @param request
 * @returns
 */
export const apiReceptionItemSelectByPatientInfoV2 = async (request: any) => {
	return await instance
		.get('/test/receptionItemSelectByPatientInfoV2', {
			params: {
				...request,
			},
		})
		.then((result) => result.data)
}

/**
 * 선택한 검사 이외에 수진자의 다른 검사 존재 여부 확인
 * @param request
 * @returns
 */
export const apiReceptionOtherExist = async (request: any) => {
	return await instance
		.get('/test/receptionOtherExist', {
			params: {
				...request,
			},
		})
		.then((result) => result.data)
}

// ---------------------- 검사결과 API 끝 ------------------

// ---------------------- 코로나 검사결과 API 시작 ------------------

/**
 * 목록 조회
 * @param search
 * @returns
 */
export const covidList = async (search: CovidInspectionResultSearchType) => {
	return await instance
		.get(`/covid/list`, {
			params: {
				...search,
			},
		})
		.then((result) => result.data)
}

/**
 * 코로나 검사 결과 엑셀 출력 (이상결과)
 * @param search
 * @returns
 */
export const covidExcelAbnormalList = async (
	search: CovidInspectionResultSearchType
) => {
	return await instance

		.get(`/covid/excel/abnormal`, {
			params: {
				...search,
			},
			responseType: 'blob',
		})
		.then((result) => {
			const { data } = result
			const date = new Date()
			const year = date.getFullYear()
			const month = date.getMonth() + 1
			const day = date.getDate()
			const hour = date.getHours()
			const minute = date.getMinutes()
			const second = date.getSeconds()
			const fileName = `코로나검사결과(이상결과)_${year}-${month}-${day}_${hour}_${minute}_${second}.xls`
			fileDownload(data, fileName)
		})
}

/**
 * 코로나 검사 결과 엑셀 출력 (음성)
 * @param search
 * @returns
 */
export const covidExcelNegativeList = async (
	search: CovidInspectionResultSearchType
) => {
	return await instance

		.get(`/covid/excel/negative`, {
			params: {
				...search,
			},
			responseType: 'blob',
		})
		.then((result) => {
			const { data } = result
			const date = new Date()
			const year = date.getFullYear()
			const month = date.getMonth() + 1
			const day = date.getDate()
			const hour = date.getHours()
			const minute = date.getMinutes()
			const second = date.getSeconds()
			const fileName = `코로나검사결과(음성)_${year}-${month}-${day}_${hour}_${minute}_${second}.xls`
			fileDownload(data, fileName)
		})
}

/**
 * 코로나 검사 결과 전체 출력
 * @param search
 * @returns
 */
export const covidExcelAllList = async (
	search: CovidInspectionResultSearchType
) => {
	return await instance

		.get(`/covid/excel/all`, {
			params: {
				...search,
			},
			responseType: 'blob',
		})
		.then((result) => {
			const { data } = result
			const date = new Date()
			const year = date.getFullYear()
			const month = date.getMonth() + 1
			const day = date.getDate()
			const hour = date.getHours()
			const minute = date.getMinutes()
			const second = date.getSeconds()
			const fileName = `코로나검사결과(전체)_${year}-${month}-${day}_${hour}_${minute}_${second}.xls`
			fileDownload(data, fileName)
		})
}

/**
 * 코로나 검사 결과 동시검사 출력
 * @param search
 * @returns
 */
export const covidExcelInspectionList = async (
	search: CovidInspectionResultSearchType
) => {
	return await instance

		.get(`/covid/excel/inspection`, {
			params: {
				...search,
			},
			responseType: 'blob',
		})
		.then((result) => {
			const { data } = result
			const date = new Date()
			const year = date.getFullYear()
			const month = date.getMonth() + 1
			const day = date.getDate()
			const hour = date.getHours()
			const minute = date.getMinutes()
			const second = date.getSeconds()
			const fileName = `코로나검사결과(전체)_${year}-${month}-${day}_${hour}_${minute}_${second}.xls`
			fileDownload(data, fileName)
		})
}

/**
 * 코로나 검사 결과 문자 전송
 */
export const sendSMS = async (receptionID: number) => {
	return await instance2
		.post(`/covid/sendSMS`, {
			receptionID,
		})
		.then((result) => result.data)
}

// ---------------------- 검사항목별, 과거이력 조회 API 시작 ------------------

/**
 * 검사항목 팝업
 * @param searchKeyword
 * @returns
 */
export const testItemList = async (searchKeyword: string) => {
	return await instance
		.get('/test/testItemList', {
			params: {
				searchKeyword,
			},
		})
		.then((result) => result.data)
}

/**
 * 과거검사항목 조회
 * @param request
 * @returns
 */
// prevReceptionList 삭제 예정
export const prevReceptionList = async (request: PrevReceptionSearchType) => {
	return await instance
		.get('/test/prevReceptionList', {
			params: {
				...request,
			},
		})
		.then((result) => result.data)
}
export const receptionSelectByOld = async (request: any) => {
	return await instance
		.get('/test/receptionSelectByOld', {
			params: {
				...request,
			},
		})
		.then((result) => result.data)
}

/**
 * 검사 항목별 조회
 * @param request
 * @returns
 */
export const receptionSelectByItem = async (request: any) => {
	return await instance
		.get('/test/receptionSelectByItem', {
			params: {
				...request,
			},
		})
		.then((result) => result.data)
}

/**
 * 코로나 검사 데이터 조회
 * @param request
 * @returns
 */
export const covidData = async (request: any) => {
	return await instance
		.get('/report/covidData', {
			params: {
				...request,
			},
		})
		.then((result) => result.data)
}

// ---------------------- 검사항목별, 과거이력 조회 API 끝 ------------------

// ---------------------- 검사항목조회 API 시작 ------------------

/**
 * 검사항목 조회
 * @param request
 * @returns
 */
export const testItemsList = async (request: any) => {
	return await instance
		.get('/cmmn/testItem/list', {
			params: {
				...request,
			},
		})
		.then((result) => result.data)
}

/**
 * 검사항목 상세
 * @param testCode
 * @returns
 */
export const testItemInfo = async (TestSpecimenID: number) => {
	return await instance
		.get(`/cmmn/testItem/view/${TestSpecimenID}`)
		.then((result) => {
			return result.data
		})
}

// ---------------------- 검사항목조회 API 끝 ------------------

// ---------------------- 검사결과연동 API 시작 ------------------

/**
 * 고객사 목록 (팝업)
 * @param search
 * @returns
 */
export const clientList = async (search: InfoClientSearch) => {
	return await instance2
		.get('/client/list', {
			params: {
				...search,
			},
		})
		.then((result) => {
			return result.data
		})
}

/**
 * 고객사 엑셀 탬플릿 지정된 목록 조회
 * @param search
 */
export const clientSyncList = async (search: InfoClientSearch) => {
	return await instance2
		.get('/sync/list', {
			params: {
				...search,
			},
		})
		.then((result) => {
			return result.data
		})
}

/**
 * 엑셀 업로드 > DB 저장
 * @param file
 * @returns
 */
export const syncUploadFile = async (file: File) => {
	const formData = new FormData()
	formData.append('file', file)

	const { data } = await instance2.post(`/sync/fileUpload`, formData, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	})

	return data
}

/**
 * 엑셀파일에 문제가 있는 업체는 json으로 업로드 처리
 * @param request
 * @returns
 */
export const jsonUpload = async (request: any) => {
	return await instance2
		.post(`/sync/jsonUpload`, request)
		.then((result) => result.data)
}

export const syncUploadTempFile = async (file: File) => {
	const formData = new FormData()
	formData.append('file', file)

	const { data } = await instance2.post(`/sync/tempFileUpload`, formData, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	})

	return data
}

/**
 * 고객사 연동 저장 or 수정
 * @param request
 * @returns
 */
export const clientSyncSave = async (request: any) => {
	return await instance2
		.post('/sync/save', request)
		.then((result) => result.data)
}

/**
 * 고객사 연동 요청
 * @param request
 * @returns
 */
export const syncRequest = async () => {
	return await instance2.post('/sync/syncRequest').then((result) => result.data)
}

/**
 * 업로드 목록 조회 SEQ join
 * @returns
 */
export const syncList = async (request: any) => {
	return await instance2
		.get('/sync/syncList', {
			params: {
				...request,
			},
		})
		.then((result) => result.data)
}

export const syncListByOcsSeq = async (request: any) => {
	return await instance2
		.get('/sync/syncListByOcsSeq', {
			params: {
				...request,
			},
		})
		.then((result) => result.data)
}

export const syncList2 = async (request: any) => {
	return await instance2
		.get('/sync/syncList2', {
			params: {
				...request,
			},
		})
		.then((result) => result.data)
}

/**
 * 업로드 목록 조회 RItemID join
 * @returns
 */
export const syncTestList = async (request: any) => {
	return await instance2
		.get('/sync/syncTestList', {
			params: {
				...request,
			},
		})
		.then((result) => result.data)
}

/**
 * clientID로 테이블 명 조회
 * @returns
 */
export const tableName = async () => {
	return await instance2.get('/sync/tableName').then((result) => result.data)
}

/**
 * 동기화 항목 삭제 (RItenID가 0이고 TestStatus가 10인 항목만 해당됨)
 * @param seq
 * @returns
 */
export const deleteSync = async (seq: number) => {
	return await instance2
		.delete(`/sync/delete/${seq}`)
		.then((result) => result.data)
}

/**'
 * 연동결과 엑셀 다운로드 JSON 리턴용
 */
export const apiSyncDownload = async (request: any) => {
	return await instance2
		.get('/sync/excelDownload', {
			params: {
				...request,
			},
		})
		.then((res) => res.data)
}

/**
 * 2024.01.08 미라벨 작업분 ocs 엑셀 다운로드
 * @param request
 * @returns
 */
export const getExcelDownload = async (request: any) => {
	return await instance2
		.get('/sync/excelDownloadJs', {
			params: {
				...request,
			},
		})
		.then((res) => res.data)
}

export const getExcelDownload2 = async (request: any) => {
	return await instance2
		.get('/sync/excelDownloadJs2', {
			params: {
				...request,
			},
		})
		.then((res) => res.data)
}

/**
 * 2024.01.08 미라벨 작업분 ocs 엑셀 다운로드
 * @param request
 * @returns
 */
export const excelDownloadRItemID = async (request: any) => {
	return await instance2
		.get('/sync/excelDownloadRItemID', {
			params: {
				...request,
			},
		})
		.then((res) => res.data)
}

/**
 * 검사결과 수진자 리스트 - 미라벨 작업분
 * @returns
 */
export const receptionListMybatis = async (
	request: InspectionResultListType
) => {
	return await instance2
		.get('/test/receptionListMybatis', { params: { ...request } })
		.then((result) => result.data)
}

// ---------------------- 검사결과연동 API 끝 ------------------

export const generatePdf = async (request: any) => {
	return await instance2
		.get('/report/generate/report2', {
			params: {
				...request,
			},
		})
		.then((result) => result.data)
}

export const textDownload = async (request: any, fileName: string) => {
	return await instance2
		.get('/sync/txtDownload', {
			params: {
				...request,
			},
			responseType: 'blob',
		})
		.then((res) => {
			fileDownload(res.data, fileName + '.txt')
		})
}

// ---------------------- 필수서류 API 시작 ------------------

/**
 * 필수서류 목록 조회
 * @param request
 * @returns
 */
export const apiDocumentList = async (request: any) => {
	return await instance
		.get('/docs/list', {
			params: {
				...request,
			},
		})
		.then((result) => result.data)
}

/**
 * 필수서류 저장
 * @param request
 * @returns
 */
export const apiDocumentSave = async (request: any) => {
	return await instance2
		.post('/docs/save', request)
		.then((result) => result.data)
}

/**
 * 필수서류 수정
 * @param request
 * @returns
 */
export const apiDocumentUpdate = async (request: any) => {
	return await instance2.put('/docs/save', request).then((result) => result.data)
}

/**
 * 필수서류 삭제
 * @param request
 * @returns
 */
export const apiDocumentDelete = async (request: any) => {
	return await instance2
		.post('/docs/delete', request)
		.then((result) => result.data)
}

// ---------------------- 필수서류 API 끝 ------------------

// ---------------------- 리포트 파일 API 시작 ------------------

/**
 * 필수서류 목록 조회
 * @param request
 * @returns
 */
export const apiRptFileList = async (request: any) => {
	return await instance
		.get('/rptFile/list', {
			params: {
				...request,
			},
		})
		.then((result) => result.data)
}

/**
 * 필수서류 저장
 * @param request
 * @returns
 */
export const apiRptFileSave = async (request: any) => {
	return await instance
		.post('/rptFile/save', request)
		.then((result) => result.data)
}

/**
 * 필수서류 수정
 * @param request
 * @returns
 */
export const apiRptFileUpdate = async (request: any) => {
	return await instance
		.put('/rptFile/save', request)
		.then((result) => result.data)
}

/**
 * 필수서류 삭제
 * @param request
 * @returns
 */
export const apiRptFileDelete = async (request: any) => {
	return await instance
		.post('/rptFile/delete', request)
		.then((result) => result.data)
}

/**
 * 수진자의 RptCodeList 조회
 * @returns
 */
export const apiRptTypeCodeList = async (request: any) => {
	return await instance
		.get('/report/reportTypeCodeList', {
			params: {
				...request,
			},
		})
		.then((result) => result.data)
}

// ---------------------- 리포트 파일 API 끝 ------------------

export const apiGb34016ExcelList = async (request: any) => {
	return await instance
		.get('/gb34016/list', {
			params: {
				...request,
			},
		})
		.then((result) => result.data)
}

export const apiGb34016ExcelListExcel = async (request: any) => {
	return await instance
		.get('/gb34016/excel', {
			params: {
				...request,
			},
		})
		.then((result) => fileDownload(result.data, 'GB34016.xls'))
}

export const apiSendSSEMessage = async (request: any) => {
	return await instance
		.post('/client/list2', request)
		.then((result) => result.data)
}

// ---------------------- 우편결과지 API 시작 ------------------

/**
 * clientID로 우편결과지 목록 조회
 * @param request
 * @returns
 */
export const apiPostItemList = async (request: any) => {
	return await instance2
		.get('/post/list', {
			params: {
				...request,
			},
		})
		.then((result) => result.data)
}

/**
 * clientID, list를 우편결과지 테이블에 저장
 * @param request
 * @returns
 */
export const apiPostItemSave = async (request: any) => {
	return instance2.post('/post/save', request).then((result) => result.data)
}

/**
 * 우편결과지 삭제
 * @param request
 * @returns
 */
export const apiPostItemDelete = async (request: any) => {
	return instance2.post('/post/delete', request).then((result) => result.data)
}

/**
 * 우편 출력용 수진자 목록
 * @param request
 * @returns
 */
export const apiPostReceptionList = async (request: any) => {
	return instance2
		.get('/test/postReceptionList', { params: { ...request } })
		.then((result) => result.data)
}

// ---------------------- 우편결과지 API 끝 -------------------

// ---------------------- SMS API 시작 -------------------

/**
 * SMS 그룹 목록 조회
 * @param request
 * @returns
 */
export const apiSMSGroupList = async (request: any) => {
	return instance2
		.get('/sms/group/list', { params: { ...request } })
		.then((result) => result.data)
}

/**
 * SMS 그룹 저장, 수정, 삭제
 * @param request
 * @returns
 */
export const apiSMSGroupSave = async (request: any) => {
	return instance2.post('/sms/group/save', request).then((result) => result.data)
}

/**
 * SMS 그룹 목록 조회
 * @param request
 * @returns
 */
export const apiSMSSlvList = async (request: any) => {
	return instance2
		.get('/sms/slave/list', { params: { ...request } })
		.then((result) => result.data)
}

/**
 * SMS 그룹 저장, 수정, 삭제
 * @param request
 * @returns
 */
export const apiSMSSlvSave = async (request: any) => {
	return instance2.post('/sms/slave/save', request).then((result) => result.data)
}

/**
 * 그룹 내 고객을 다른 그룹으로 복사 또는 이동
 * @param request
 * @returns
 */
export const apiSlaveCopy = async (request: any) => {
	return instance2.post('/sms/slave/copy', request).then((result) => result.data)
}

/**
 * SMS를 사용하는 고객사 목록 조회
 * @param request
 * @returns
 */
export const apiClientSmsMstList = async (request: any) => {
	return instance2
		.get('/smsClient/mst/list', { params: { ...request } })
		.then((result) => result.data)
}

/**
 * SMS를 사용하는 고객사 CUD
 * @param request
 * @returns
 */
export const apiClientSMSMstSave = async (request: any) => {
	return instance2
		.post('/smsClient/mst/save', request)
		.then((result) => result.data)
}

/**
 * SMS를 사용하는 고객사의 서브 목록 조회
 * @param request
 * @returns
 */
export const apiClientSmsSlvList = async (request: any) => {
	return instance2
		.get('/smsClient/slv/list', { params: { ...request } })
		.then((result) => result.data)
}

/**
 * SMS를 사용하는 고객사의 서브 CUD
 * @param request
 * @returns
 */
export const apiClientSMSSlvSave = async (request: any) => {
	return instance2
		.post('/smsClient/slv/save', request)
		.then((result) => result.data)
}

/**
 * SMS 발송 결과 조회
 * @param request
 * @returns
 */
export const apiSmsResultList = async (request: any) => {
	return instance2
		.get('/sms/message/result', { params: { ...request } })
		.then((result) => result.data)
}

/**
 * SMS 메시지 발송
 * @param request
 * @returns
 */
export const apiSmsSend = async (request: any) => {
	return instance2
		.post('/sms/message/send', request)
		.then((result) => result.data)
}

/**
 * SMS 탬플릿 마스터 목록 조회
 * @param request
 * @returns
 */
export const apiSmsTemplateMstList = async (request: any) => {
	return instance2
		.get('/sms/template/group/list', { params: { ...request } })
		.then((result) => result.data)
}

/**
 * SMS 탬플릿 서브 목록 조회
 * @param request
 * @returns
 */
export const apiSmsTemplateSlvList = async (request: any) => {
	return instance2
		.get('/sms/template/slave/list', { params: { ...request } })
		.then((result) => result.data)
}

/**
 * SMS 탬플릿 마스터 저장, 수정, 삭제
 * @param request
 * @returns
 */
export const apiSmsTemplateMstSave = async (request: any) => {
	return instance2
		.post('/sms/template/group/save', request)
		.then((result) => result.data)
}

/**
 * SMS 탬플릿 서브 저장, 수정, 삭제
 * @param request
 * @returns
 */
export const apiSmsTemplateSlvSave = async (request: any) => {
	return instance2
		.post('/sms/template/slave/save', request)
		.then((result) => result.data)
}

/**
 * SMS 발신 예약 목록
 * @param request
 * @returns
 */
export const apiSmsScheduleList = async (request: any) => {
	return instance2
		.get('/sms/message/schedule', { params: { ...request } })
		.then((result) => result.data)
}

/**
 * 메시지 카테고리 목록
 * @param request
 * @returns
 */
export const apiTemplateGroupList = async (request: any) => {
	return instance2
		.get('/sms/template/templateGroupList', { params: { ...request } })
		.then((res) => res.data)
}

/**
 * SMS 전송 목록 및 전송 예약 조회
 * @param request
 * @returns
 */
export const apiSmsSendAndReserveList = async (request: any) => {
	return instance2
		.get('/sms/message/smsSendAndReserveList', { params: { ...request } })
		.then((res) => res.data)
}

/**'
 * SMS 전송 예약 삭제
 */
export const apiSmsScheduleDelete = async (request: any) => {
	return instance2
		.post('/sms/message/schedule/delete', request)
		.then((res) => res.data)
}

/**
 * SMS 발송 예약 목록 조회
 * @param request
 * @returns
 */
export const apiSmsReservationList = async (request: any) => {
	return instance2
		.get('/sms/message/reservationList', { params: { ...request } })
		.then((res) => res.data)
}

/**
 * 고객 그룹의 트리 구조
 * @param request
 * @returns
 */
export const apiSmsGroupTreeList = async (request: any) => {
	return instance2
		.get('/sms/tree/list', { params: { ...request } })
		.then((res) => res.data)
}

/**
 * SMS 그룹 목록 조회 (개인)
 * @param request
 * @returns
 */
export const apiSMSGroupPrvList = async (request: any) => {
	return instance2
		.get('/sms/group/prvList', { params: { ...request } })
		.then((result) => result.data)
}

/**
 * SMS 그룹 저장, 수정, 삭제 (개인)
 * @param request
 * @returns
 */
export const apiSMSGroupPrvSave = async (request: any) => {
	return instance2
		.post('/sms/group/prvSave', request)
		.then((result) => result.data)
}

/**
 * SMS 그룹 목록 조회 (개인)
 * @param request
 * @returns
 */
export const apiSMSSlvPrvList = async (request: any) => {
	return instance2
		.get('/sms/slave/prvList', { params: { ...request } })
		.then((result) => result.data)
}

/**
 * SMS 그룹 저장, 수정, 삭제 (개인)
 * @param request
 * @returns
 */
export const apiSMSSlvPrvSave = async (request: any) => {
	return instance2
		.post('/sms/slave/prvSave', request)
		.then((result) => result.data)
}

/**
 * 그룹 내 고객을 다른 그룹으로 복사 또는 이동 (개인)
 * @param request
 * @returns
 */
export const apiSlavePrvCopy = async (request: any) => {
	return instance2
		.post('/sms/slave/prvCopy', request)
		.then((result) => result.data)
}

/**
 * 월별 전송 건수 리턴
 * @param request
 * @returns
 */
export const apiSmsCount = async () => {
	return instance2.get('/sms/message/count/month').then((result) => result.data)
}

/**
 * 거래처별 사용자 정의 메시지 저장
 * @param request
 * @returns
 */
export const apiCustomMessageSave = async (request: any) => {
	return instance2
		.post('/sms/template/custom/save', request)
		.then((result) => result.data)
}

/**
 * 월별 SMS 셋팅 건수 조회
 * @param request
 * @returns
 */
export const apiSmSMonthSelect = async (request: any) => {
	return instance2
		.get('/sms/message/month/select', { params: { ...request } })
		.then((result) => result.data)
}

/**
 * 월별 SMS 셋팅 건수 저장
 * @param request
 * @returns
 */
export const apiSmsMonthSave = async (request: any) => {
	return instance2
		.post('/sms/message/month/save', request)
		.then((result) => result.data)
}

// ---------------------- SMS API 끝 --------------------

// ---------------------- Slide/Block 반출 신청 API 끝 --------------------

/**
 * 수진자 목록
 * @param request
 */
export const apiSBReceptionList = async (request: any) => {
	return instance
		.get('/sb/receptionList', { params: { ...request } })
		.then((result) => result.data)
}

/**
 * 수진자 목록 (슬라이드 대여 결과 조회)
 * @param request
 */
export const apiReceptionResultList = async (request: any) => {
	return instance
		.get('/sb/receptionResultList', { params: { ...request } })
		.then((result) => result.data)
}

/**
 * Slide/Block 리스트 조회
 * @returns
 */
export const apiSBList = async () => {
	return instance.get('/sb/sbList').then((result) => result.data)
}

/**
 * Slide/Block 외부반출 신청
 * @param request
 * @returns
 */
export const apiSBReserve = async (request: any) => {
	return instance2.post('/sb/sbReserve', request).then((result) => result.data)
}

/**
 * Slide/Block 수진자 정보 조회
 * @param request
 * @returns
 */
export const apiSBReceptionInfoList = async (request: any) => {
	return instance
		.get('/sb/receptionInfoList', { params: { ...request } })
		.then((result) => result.data)
}

/**
 * 수진자 목록
 * @param request
 */
export const apiReceptionSubList = async (request: any) => {
	return instance
		.get('/sb/receptionSubList', { params: { ...request } })
		.then((result) => result.data)
}

// ---------------------- Slide/Block 반출 신청 API 끝 --------------------

// ---------------------- 설문조사 API 시작 --------------------

/**
 * 설문 마스터 목록 조회
 * @param request
 * @returns
 */
export const apiSurveyMstList = async (request: any) => {
	return instance2
		.get('/survey/mst/list', { params: { ...request } })
		.then((result) => result.data)
}

/**
 * 설문 서브 목록 조회
 * @param request
 * @returns
 */
export const apiSurveySlvList = async (request: any) => {
	return instance2
		.get('/survey/slv/list', { params: { ...request } })
		.then((result) => result.data)
}

/**
 * 설문 상세 홍목 목록 조회
 * @param request
 * @returns
 */
export const apiSurveyDetailList = async (request: any) => {
	return instance2
		.get('/survey/detail/list', { params: { ...request } })
		.then((result) => result.data)
}

/**
 * 설문 마스터 저장 (CUD)
 * @param request
 * @returns
 */
export const apiMstSave = async (request: any) => {
	return instance2
		.post('/survey/mst/save', request)
		.then((result) => result.data)
}

/**
 * 설문 서브 저장 (CUD)
 * @param request
 * @returns
 */
export const apiSlvSave = async (request: any) => {
	return instance2
		.post('/survey/slv/save', request)
		.then((result) => result.data)
}

/**
 * 설문 상세 항목 저장 (CUD)
 * @param request
 * @returns
 */
export const apiDetailSave = async (request: any) => {
	return instance2
		.post('/survey/detail/save', request)
		.then((result) => result.data)
}

/**
 * 설문 목록 조회
 * @param request
 * @returns
 */
export const apiSurveyList = async (request: any) => {
	return instance2
		.get('/survey/list', { params: { ...request } })
		.then((result) => result.data)
}

/**
 * 설문 결과 저장
 * @param request
 * @returns
 */
export const apiSurveyResultSave = async (request: any) => {
	return instance2
		.post('/survey/surveyResultSave', request)
		.then((result) => result.data)
}

/**
 * 설문 결과 조회
 * @param request
 * @returns
 */
export const apiSurveyResult = async (request: any) => {
	return instance2
		.get('/survey/surveyResult', { params: { ...request } })
		.then((result) => result.data)
}

// ---------------------- 설문조사 API 끝 --------------------

// ---------------------- 계정 잠김 API 시작 --------------------

/**
 * 계정 잠김 목록 조회
 * @param request
 * @returns
 */
export const apiAccountLockList = async (request: any) => {
	return instance2
		.get('/user/accountLockList', { params: { ...request } })
		.then((result) => result.data)
}

/**
 * 계정 잠금 항목 삭제
 * @param request
 * @returns
 */
export const apiDeleteAccountLock = async (request: any) => {
	return instance2
		.post('/user/deleteAccountLock', request)
		.then((result) => result.data)
}

// ---------------------- 계정 잠김 API 끝 --------------------

// ---------------------- TAT 조회 API 시작 --------------------

/**
 * 수진자명 기준의 TAT 목록 조회
 * @param request
 * @returns
 */
export const apiTatListByPatientName = async (request: any) => {
	return await instance
		.get('/tat/listByPatientName', {
			params: {
				...request,
			},
		})
		.then((result) => result.data)
}

/**
 * 항목별 TAT 목록 조회
 * @param request
 * @returns
 */
export const apiTatListByItem = async (request: any) => {
	return await instance
		.get('/tat/listByItem', {
			params: {
				...request,
			},
		})
		.then((result) => result.data)
}

// ---------------------- TAT 조회 API 끝 --------------------
