import React, { useState, useEffect } from 'react'
import { AppBar, Toolbar, Typography, IconButton } from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'
import CircularProgress from '@mui/material/CircularProgress'

import { selectuserInfo } from 'hooks/userInfo'
import { useSelector } from 'react-redux'

import { reportText } from 'api/api'

import { replaceBrTag } from 'util/stringUtil'

const ReportText = () => {
	const userInfo = useSelector(selectuserInfo)

	const [isLoading, setIsLoading] = useState(false)

	const [receptionHeader, setReceptionHeader] = useState<any>({})
	const [resultList, setResultList] = useState<any>([])
	const [noResultList, setNoResultList] = useState<any>([])
	const [isP0, setIsP0] = useState(false)
	const [isReportTypeB, setIsReportTypeB] = useState(false)
	const [findings, setFindings] = useState('')

	useEffect(() => {
		console.log(userInfo)

		const params = new URLSearchParams(window.location.search)
		const receptionID = params.get('receptionID')
		const clientID = params.get('clientID')
		setIsReportTypeB(params.get('isReportTypeB') === 'Y' ? true : false)
		setIsP0(params.get('isP0') === 'Y' ? true : false)

		console.log(receptionID, clientID)
		if (receptionID && clientID) {
			setIsLoading(true)
			const request = {
				receptionID: receptionID,
				clientID: clientID,
			}
			reportText(request)
				.then((res) => {
					console.log(res)

					let tmp = false
					if (res.header?.InsuranceType === '4') tmp = true
					res.result?.forEach((item: any) => {
						if (item.TestCode === 'H201100') {
							tmp = true
						}
					})

					console.log(tmp)

					if (!tmp && res.header?.LastStateDate?.length > 10) {
						res.header?.LastStateDate.substring(0, 10)
					}

					setReceptionHeader(res.header)
					setResultList(res.result)
					setNoResultList(res.noResult)

					if (params.get('isReportTypeB') === 'Y') {
						res.result?.forEach((item: any) => {
							if (item.Findings) {
								setFindings(item.Findings)
							}
						})
					}
				})
				.finally(() => {
					setIsLoading(false)
				})
		}
	}, [])

	return (
		<>
			<AppBar
				sx={{ position: 'sticky', backgroundColor: '#323036' }}
				className="noPrint"
			>
				<Toolbar>
					<Typography
						sx={{ flex: 1, fontSize: '16px', margin: 0, lineHeight: 1 }}
						variant="h6"
						component="p"
					>
						텍스트 결과보고서
					</Typography>
					<IconButton
						edge="end"
						color="inherit"
						aria-label="close"
						onClick={() => {
							window.close()
						}}
					>
						<CloseIcon />
					</IconButton>
				</Toolbar>
			</AppBar>
			{isLoading && (
				<div className="loadingSpinner">
					<div className="spinner">
						<CircularProgress color="secondary" />
					</div>
				</div>
			)}
			<div
				className="printBody1"
				style={{ padding: '1rem' }}
			>
				<table className="reportHeaderTable">
					<colgroup>
						<col style={{ width: '110px' }} />
						<col />
						<col />
						<col />
						<col style={{ width: '110px' }} />
						<col style={{ width: '130px' }} />
					</colgroup>
					<tbody>
						<tr>
							<th>
								<p>의뢰기관명</p>
							</th>
							<td
								style={{ width: '440px' }}
								colSpan={3}
							>
								{receptionHeader?.ClientName || ' '}
							</td>
							<th style={{ width: '110px' }}>검체채취일</th>
							<td style={{ width: '130px' }}>
								{receptionHeader?.CollectedDate
									? receptionHeader?.CollectedDate.substring(0, 10)
									: ' '}
							</td>
						</tr>
						<tr>
							<th>수진자명</th>
							<td
								style={{ width: '440px' }}
								colSpan={3}
							>
								{receptionHeader?.PatientName || ' '}
							</td>
							<th style={{ width: '110px' }}>검체접수일</th>
							<td style={{ width: '130px' }}>
								{receptionHeader?.ReceptionDate || ' '}
							</td>
						</tr>
						<tr>
							<th>진료과/병동</th>
							<td style={{ width: '230px' }}>{receptionHeader?.WardInfo || ' '}</td>
							<th style={{ width: '110px' }}>의사명</th>
							<td>{receptionHeader?.DoctorName || ' '}</td>
							<th style={{ width: '110px' }}>결과보고일</th>
							<td style={{ width: '130px' }}>{receptionHeader?.LastStateDate}</td>
						</tr>
						<tr>
							<th>차트번호</th>
							<td style={{ width: '230px' }}>{receptionHeader?.ChartNo || ' '}</td>
							<th style={{ width: '110px' }}>접수번호</th>
							<td>{receptionHeader?.ReceptionRegNum || ' '}</td>
							<th style={{ width: '110px' }}>기타</th>
							<td style={{ width: '130px' }}>{receptionHeader?.ReportMemo}</td>
						</tr>
						<tr>
							<th>성별/나이</th>
							<td style={{ width: '230px' }}>
								{receptionHeader?.ReceptionUserInfo || ' '}
							</td>
							<th style={{ width: '110px' }}>총뇨량</th>
							<td colSpan={3}>{receptionHeader?.TotalUrineVolume || ' '}</td>
						</tr>
						<tr>
							<th>검체종류</th>
							<td colSpan={5}>{receptionHeader?.SpecimenName || ' '}</td>
						</tr>
					</tbody>
				</table>
				<table className="reportInfoTable">
					<colgroup>
						<col style={{ width: 80 }} />
						<col />
						<col />
						<col />
						<col />
						<col />
					</colgroup>
					<thead>
						<tr>
							<th>보험코드</th>
							<th>검사항목</th>
							<th>결과</th>
							<th>판정</th>
							<th>참고치</th>
							<th>단위</th>
						</tr>
					</thead>
					<tbody>
						{isReportTypeB &&
							resultList.map((item: any, index: number) => {
								return (
									<React.Fragment key={index}>
										<tr>
											{/* <td rowSpan={item.InsuranceCode && 2}>{item.InsuranceCode}</td> */}
											<td rowSpan={(item.ResultText || item.ResultText2) && 2}>
												{item.InsuranceCode}
											</td>
											<td>{item.TestName}</td>
											<td>{item.ResultData}</td>
											<td>{item.Decision}</td>
											<td>{item.Reference}</td>
											<td>{item.Unit}</td>
										</tr>
										{(item.ResultText || item.ResultText2) && (
											<>
												<tr>
													<td colSpan={5}>
														<span
															dangerouslySetInnerHTML={{
																__html: replaceBrTag(item.ResultText),
															}}
														></span>
														{item.ResultText && <br />}
														<span
															dangerouslySetInnerHTML={{
																__html: replaceBrTag(item.ResultText2),
															}}
														></span>
													</td>
												</tr>
											</>
										)}
										{index === resultList.length - 1 && (
											<>
												<tr>
													<td></td>
													<td colSpan={5}>
														<span
															dangerouslySetInnerHTML={{
																__html: replaceBrTag(findings),
															}}
														></span>
													</td>
												</tr>
											</>
										)}
									</React.Fragment>
								)
							})}
						{!isReportTypeB &&
							resultList.map((item: any, index: number) => {
								return (
									<React.Fragment key={index}>
										<tr>
											{/* <td rowSpan={item.InsuranceCode && 2}>{item.InsuranceCode}</td> */}
											<td rowSpan={(item.ResultText || item.ResultText2) && 2}>
												{item.InsuranceCode}
											</td>
											<td>{item.TestName}</td>
											<td>{item.ResultData}</td>
											<td>{item.Decision}</td>
											<td>{item.Reference}</td>
											<td>{item.Unit}</td>
										</tr>
										{item.Findings && (
											<tr>
												<td></td>
												<td colSpan={5}>
													<span
														dangerouslySetInnerHTML={{
															__html: replaceBrTag(item.Findings),
														}}
													></span>
												</td>
											</tr>
										)}
										{(item.ResultText || item.ResultText2) && (
											<>
												<tr>
													<td colSpan={5}>
														<span
															dangerouslySetInnerHTML={{
																__html: replaceBrTag(item.ResultText),
															}}
														></span>
														{item.ResultText && <br />}
														<span
															dangerouslySetInnerHTML={{
																__html: replaceBrTag(item.ResultText2),
															}}
														></span>
													</td>
												</tr>
											</>
										)}
									</React.Fragment>
								)
							})}
					</tbody>
				</table>
				{noResultList.length > 0 && (
					<>
						<Typography
							variant="h6"
							component="p"
							sx={{ fontSize: '12px' }}
						>
							미결과 항목
						</Typography>
						<table className="reportInfoTable">
							<colgroup>
								<col style={{ width: 80 }} />
								<col />
								<col />
								<col />
								<col />
								<col />
							</colgroup>
							<thead>
								<tr>
									<th>보험코드</th>
									<th>검사항목</th>
									<th>결과</th>
									<th>판정</th>
									<th>참고치</th>
									<th>단위</th>
								</tr>
							</thead>
							<tbody>
								{noResultList.map((item: any, index: number) => {
									return (
										<React.Fragment key={index}>
											<tr>
												<td rowSpan={item.ResultText && 2}>{item.InsuranceCode}</td>
												<td>{item.TestName}</td>
												<td>{isP0 ? item.ResultPlanDate : item.ResultData}</td>
												<td>{item.Decision}</td>
												<td>{item.Reference}</td>
												<td>{item.Unit}</td>
											</tr>
											{item.ResultText && (
												<tr>
													<td
														colSpan={5}
														dangerouslySetInnerHTML={{
															__html: replaceBrTag(item.ResultText),
														}}
													></td>
												</tr>
											)}
										</React.Fragment>
									)
								})}
							</tbody>
						</table>
					</>
				)}
			</div>
		</>
	)
}

export default ReportText
