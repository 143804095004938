import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import ContentPasteSearchTwoToneIcon from '@mui/icons-material/ContentPasteSearchTwoTone'
import {
	Stack,
	Grid,
	Paper,
	Box,
	Button,
	TextField,
	Typography,
	LinearProgress,
	useMediaQuery,
	Breadcrumbs,
} from '@mui/material'
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone'
import LockIcon from '@mui/icons-material/Lock'
import {
	DataGridPremium,
	koKR,
	GridSelectionModel,
	GridDensity,
	useGridApiRef,
	GridToolbar,
} from '@mui/x-data-grid-premium'
import { styled, useTheme } from '@mui/material/styles'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/ko'
import { LocalizationProvider } from '@mui/x-date-pickers'

import { selectuserInfo } from 'hooks/userInfo'
import { getSettings } from 'helpers/storage'

import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import { alertModal, hexToRgba } from 'util/util'

import { apiAccountLockList, apiDeleteAccountLock } from 'api/api'

import { PageBg } from 'components/ComponentStyles'

import { selectCmmnCode } from 'hooks/cmmnCodeFactory'

const locales = ['ko'] as const

const PageBg2 = styled('div')(({ theme }) => ({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	padding: '1rem',
	borderRadius: '0.5rem',
	background: `${hexToRgba(theme.palette.custom.wrapper, 1)}`,
	// filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.1))',
	[`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
		flexDirection: 'column',
		borderRadius: 0,
	},
	// backdropFilter: 'blur(10px)',
}))

const PageWrap = styled('div')(({ theme }) => ({
	marginBottom: '1rem',
	position: 'relative',
	top: '0',
	left: '0',
	right: '0',
	zIndex: '110',
	[`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
		//marginLeft: '-1.5rem',
		//marginRight: '-1.5rem',
		top: '0',
		//borderRadius: '0',
	},
}))

const BtnBox = styled(Box)(({ theme }) => ({
	// [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
	// 	marginTop: '1rem',
	// },
}))

const TitleText = styled(Typography)(({ theme }) => ({
	[`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
		display: 'none',
	},
}))

const SearchRowItem = styled(Box)(({ theme }) => ({
	position: 'relative',
	display: 'block',
	paddingLeft: '80px',
	minHeight: '44px',
	marginBottom: '10px',
	'&.noLabel': {
		paddingLeft: '0',
	},
	'.labelText': {
		position: 'absolute',
		left: '10px',
		top: '15px',
		[`@media (max-width: ${theme.breakpoints.values.lg}px)`]: {
			position: 'relative',
			left: '0',
			top: '0',
			marginBottom: '0.5rem',
		},
	},
	[`@media (max-width: ${theme.breakpoints.values.lg}px)`]: {
		paddingLeft: '0',
		height: 'auto',
	},
}))

const AccountLock = () => {
	const theme = useTheme()
	const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
	const isLgUp = useMediaQuery(theme.breakpoints.up('lg'))

	const userInfo = useSelector(selectuserInfo)
	// 공통코드 redux store
	const cmmnCdList = useSelector(selectCmmnCode)

	const env = getSettings()

	const gridRef = useGridApiRef()

	const [locale, setLocale] = useState<(typeof locales)[number]>('ko')

	// ui state
	const [disabledSave, setDisabledSave] = useState(true)
	const [disabledDel, setDisabledDel] = useState(true)
	const [disabledSearch, setDisabledSearch] = useState(false)
	const [disabledReset, setDisabledReset] = useState(false)

	// 검색 조건
	const [dateRange, setDateRange] = useState('7d')
	const [search, setSearch] = useState({
		clientID: '',
		userID: '',
		clientName: '',
	})
	const [testNames, setTestNames] = useState<string>('')
	// 조회조건 (검체종류)
	const [testCdList, setTestCdList] = useState<any>([])

	const actionReset = () => {
		setSearch({
			clientID: '',
			userID: '',
			clientName: '',
		})
		gridRef.current?.setQuickFilterValues([])
		gridRef.current?.setPage(0)
	}

	const actionSearch = () => {
		apiAccountLockList(search).then((res) => {
			console.log(res)
			setRows(res)
		})
	}

	// grid state
	const mstRef = useGridApiRef()
	const [rows, setRows] = useState([])
	const [gridMstLoading, setGridMstLoading] = useState(false)
	const [gridMstPageSize, setGridMstPageSize] = useState(env.row ? env.row : 100)

	const onKeyUp = (e: any) => {
		if (e.keyCode === 13) actionSearch()
	}

	const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([])
	const actionDel = () => {
		console.log(selectionModel)
		rows.forEach((row: any, index: number) => {
			if (selectionModel.includes(row.id)) {
				const request = {
					clientID: row.clientID,
					userID: row.LoginID,
				}

				apiDeleteAccountLock(request).then((res) => {
					console.log(res)
				})
				if (index === rows.length - 1) {
					alertModal('삭제되었습니다.', 'success', () => {})
					setSelectionModel([])
					actionSearch()
				}
			}
		})
	}

	useEffect(() => {
		actionSearch()
	}, [])

	return (
		<>
			<Stack
				sx={{
					position: 'sticky',
					top: '50px',
					zIndex: 100,
					padding: '0.5rem',
					borderRadius: '0.5rem',
					backgroundColor:
						theme.palette.mode === 'dark' ? 'rgba(50, 48, 54, 1)' : '#fff',
					'@media (max-width: 600px)': {
						position: 'relative',
						paddingTop: '15px',
						top: '0px',
					},
				}}
			>
				<PageWrap style={{ marginBottom: '0.5rem' }}>
					<PageBg2 sx={{ padding: '0.3rem 0.3rem 0rem 0.5rem' }}>
						<Breadcrumbs
							separator={<NavigateNextIcon fontSize="small" />}
							aria-label="breadcrumb"
							sx={{
								'@media (max-width: 900px': {
									display: 'none',
								},
							}}
						>
							<TitleText
								key="3"
								color="text.primary"
								sx={{ fontWeight: '500', fontSize: 17 }}
							>
								계정 잠김 현황
							</TitleText>
						</Breadcrumbs>
						<BtnBox>
							<div>
								<span>
									<Button
										size="small"
										color="success"
										variant="contained"
										startIcon={isMdUp && <RotateLeftIcon />}
										sx={{
											marginRight: '0.5rem',
											padding: isMdUp ? '4px 10px' : '4px 6px',
											minWidth: isMdUp ? '64px' : '50px',
										}}
										disabled={disabledReset}
										onClick={actionReset}
									>
										초기화
									</Button>
								</span>
								<span>
									<Button
										size="small"
										color="primary"
										variant="contained"
										startIcon={isMdUp && <ContentPasteSearchTwoToneIcon />}
										sx={{
											marginRight: '0.5rem',
											padding: isMdUp ? '4px 10px' : '4px 6px',
											minWidth: isMdUp ? '64px' : '50px',
										}}
										disabled={disabledSearch}
										onClick={actionSearch}
									>
										조회
									</Button>
								</span>
								<span>
									<Button
										color="warning"
										size="small"
										variant="contained"
										startIcon={isMdUp && <DeleteForeverTwoToneIcon />}
										sx={{
											padding: isMdUp ? '4px 10px' : '4px 6px',
											minWidth: isMdUp ? '64px' : '50px',
										}}
										onClick={actionDel}
										disabled={selectionModel.length === 0}
									>
										삭제
									</Button>
								</span>
							</div>
						</BtnBox>
					</PageBg2>
				</PageWrap>
				<Box
					component="form"
					noValidate
					autoComplete="off"
					onKeyUp={onKeyUp}
					sx={{
						borderTop:
							theme.palette.mode === 'dark'
								? '1px solid rgba(81, 81, 81, 1)'
								: '1px solid #ccc',
						paddingTop: '1rem',
					}}
				>
					<LocalizationProvider
						dateAdapter={AdapterDayjs}
						adapterLocale={locale}
					>
						<Grid
							container
							spacing={1}
							sx={{ marginTop: '0.1rem' }}
						>
							<Grid
								item
								xs={6}
								sm={3}
								lg={3}
							>
								<TextField
									label="거래처명"
									variant="outlined"
									size="small"
									type="text"
									fullWidth
									value={search.clientName}
									onChange={(e) => {
										setSearch({
											...search,
											clientName: e.target.value,
										})
									}}
								/>
							</Grid>
							<Grid
								item
								xs={6}
								sm={3}
								lg={3}
							>
								<TextField
									label="아이디"
									variant="outlined"
									size="small"
									type="text"
									fullWidth
									aria-readonly={true}
									value={search.userID}
									onChange={(e) => {
										setSearch({
											...search,
											userID: e.target.value,
										})
									}}
								/>
							</Grid>
						</Grid>
					</LocalizationProvider>
				</Box>
			</Stack>
			<Paper
				elevation={0}
				sx={{ width: '100%', overflow: 'hidden', mt: 2 }}
			>
				<Grid container>
					<Grid
						item
						xs={12}
					>
						<PageBg>
							<Box
								sx={{
									height: 'calc(100vh - 300px)',
									'@media (max-width: 1199px)': {
										height: '400px',
										overflow: 'auto',
									},
								}}
							>
								<DataGridPremium
									rows={rows}
									apiRef={mstRef}
									experimentalFeatures={{ newEditingApi: true }}
									columns={[
										{
											field: 'clientID',
											headerName: '거래처코드',
											minWidth: 100,
											editable: false,
											headerAlign: 'center',
										},
										{
											field: 'clientName',
											headerName: '거래처명',
											minWidth: 300,
											editable: false,
											headerAlign: 'center',
										},
										{
											field: 'LoginID',
											headerName: '아이디',
											minWidth: 200,
											editable: false,
											headerAlign: 'center',
										},
										{
											field: 'Cnt',
											headerName: '로그인 시도 횟수',
											minWidth: 150,
											editable: false,
											headerAlign: 'center',
											renderCell: (params) => {
												const { value } = params
												if (value >= 5) {
													return (
														<p style={{ display: 'flex', alignItems: 'center' }}>
															<LockIcon /> {value}
														</p>
													)
												} else {
													return <span>{value}</span>
												}
											},
										},
										{
											field: 'LastLoginDate',
											headerName: '마지막 로그인 일시',
											minWidth: 200,
											editable: false,
											headerAlign: 'center',
										},
									]}
									checkboxSelection
									selectionModel={selectionModel}
									onSelectionModelChange={(newSelection) => {
										setSelectionModel(newSelection)
									}}
									pageSize={gridMstPageSize}
									onPageSizeChange={(newPageSize) => setGridMstPageSize(newPageSize)}
									rowsPerPageOptions={[5, 10, 20, 50, 100]}
									pagination
									autoHeight={false}
									localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
									loading={gridMstLoading}
									components={{
										Toolbar: GridToolbar,
										LoadingOverlay: LinearProgress,
									}}
									componentsProps={{
										toolbar: {
											csvOptions: { disableToolbarButton: true },
											excelOptions: { disableToolbarButton: true },
											printOptions: { disableToolbarButton: true },
											showQuickFilter: false,
											quickFilterProps: { debounceMs: 250 },
										},
									}}
									density={(env.desnse as GridDensity) || 'compact'}
									initialState={{
										columns: {
											columnVisibilityModel: {
												RowCrud: false,
												ReceptionID: false,
												ClientName: false,
												PartCode: false,
											},
										},
									}}
									sx={{
										'& .MuiDataGrid-columnHeaderTitleContainer': {
											fontSize: '13px',
										},
										'& .MuiDataGrid-cell': {
											fontSize: '13px !important',
											borderRight:
												theme.palette.mode === 'dark'
													? '1px solid rgba(81, 81, 81, 1)'
													: '1px solid rgba(224, 224, 224, 1)',
										},
									}}
								/>
							</Box>
						</PageBg>
					</Grid>
				</Grid>
			</Paper>
		</>
	)
}
export default AccountLock
