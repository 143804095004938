import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import {
	AppBar,
	IconButton,
	LinearProgress,
	Toolbar,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material'
import TransitionComponent from 'components/TransitionComponent'
import CloseIcon from '@mui/icons-material/Close'

import {
	DataGridPremium,
	koKR,
	GridColDef,
	GridToolbar,
	GridDensity,
} from '@mui/x-data-grid-premium'
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import { getSettings } from 'helpers/storage'

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
)

/**
 * 컬럼
 */
const GridColumn: GridColDef[] = [
	{
		field: 'ReceptionDate',
		headerName: '접수일자',
		type: 'string',
		width: 95,
		editable: false,
	},
	{
		field: 'ReceptionRegNum',
		headerName: '접수번호',
		type: 'string',
		width: 80,
		editable: false,
	},
	{
		field: 'ResultData',
		headerName: '결과',
		type: 'string',
		width: 130,
		editable: false,
	},
	{
		field: 'Decision',
		headerName: '판정',
		type: 'string',
		width: 150,
		editable: false,
	},
	{
		field: 'Reference',
		headerName: '참고치',
		type: 'string',
		width: 150,
		editable: false,
	},
]

const PrevResult = ({
	prevResultList,
	prevResultClose,
	prevResultDialog,
	patientInfo,
}: {
	prevResultList: any[]
	prevResultClose: () => void
	prevResultDialog: boolean
	patientInfo: any
}) => {
	const theme = useTheme()
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

	const env = getSettings()

	const [isLoading, setIsLoading] = React.useState(false)
	const [size, setSize] = React.useState(10)

	const options = {
		maintainAspectRatio: true,
		responsive: true,
		aspectRatio: 2,
		plugins: {
			legend: {
				position: 'top' as const,
				display: false,
			},
			title: {
				display: false,
				text: '이전 결과',
			},
		},
	}

	const labels = prevResultList.map((item: any) => {
		if (parseFloat(item.ResultData)) {
			return item.ReceptionDate
		}
	})

	const data = {
		labels,
		datasets: [
			{
				label: '결과',
				data: prevResultList.map((item: any) => {
					return parseFloat(item.ResultData)
				}),
				borderColor: 'rgb(255, 99, 132)',
				backgroundColor: 'rgba(255, 99, 132, 0.5)',
			},
			{
				label: '참고치(최저)',
				fill: false,
				backgroundColor: '#0000ff',
				borderColor: '#0000ff',
				borderDash: [3, 5],
				hidden: prevResultList[0]?.ref1Hidden,
				data: prevResultList.map((item: any) => {
					return parseFloat(item.ref1)
				}),
			},
			{
				label: '참고치(최대)',
				fill: false,
				backgroundColor: '#ff0000',
				borderColor: '#ff0000',
				borderDash: [3, 5],
				hidden: prevResultList[0]?.ref2Hidden,
				data: prevResultList.map((item: any) => {
					return parseFloat(item.ref2)
				}),
			},
		],
	}

	React.useEffect(() => {
		console.log(prevResultList)
		prevResultList.map((item: any) => {
			console.log(item.ResultData)
		})
	}, [])

	return (
		<>
			<Dialog
				open={prevResultDialog}
				TransitionComponent={TransitionComponent}
				keepMounted
				fullScreen={fullScreen}
				onClose={prevResultClose}
				maxWidth="sm"
				fullWidth
				aria-describedby="이전결과 팝업"
			>
				<AppBar
					sx={{ position: 'sticky' }}
					className="noPrint"
				>
					<Toolbar>
						<Typography
							sx={{ ml: 2, flex: 1 }}
							variant="h6"
							component="p"
						>
							{patientInfo?.PatientName + '(' + patientInfo?.ChartNo + ')님의'}{' '}
							이전결과
						</Typography>
						<IconButton
							edge="end"
							color="inherit"
							aria-label="close"
							onClick={prevResultClose}
						>
							<CloseIcon />
						</IconButton>
					</Toolbar>
				</AppBar>
				<DialogContent sx={{ paddingTop: '1rem' }}>
					<div style={{ backgroundColor: '#fff', padding: '1rem', width: '100%' }}>
						<Line
							options={options}
							data={data}
						/>
					</div>
					<div
						style={{
							height: '300px',
							width: '100%',
							overflow: 'auto',
							marginTop: '1rem',
						}}
					>
						<DataGridPremium
							rows={prevResultList}
							getRowId={(row) => row.rowNum}
							columns={GridColumn}
							pageSize={env.row ? env.row : 100}
							onPageSizeChange={(newPageSize) => setSize(newPageSize)}
							rowsPerPageOptions={[5, 10, 20, 50, 100, 500, 1000]}
							pagination
							autoHeight={false}
							localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
							density={(env.desnse as GridDensity) || 'compact'}
							components={{
								Toolbar: GridToolbar,
								LoadingOverlay: LinearProgress,
							}}
							componentsProps={{
								toolbar: {
									csvOptions: { disableToolbarButton: true },
									excelOptions: { disableToolbarButton: true },
									printOptions: { disableToolbarButton: true },
									showQuickFilter: true,
									quickFilterProps: { debounceMs: 250 },
								},
							}}
							loading={isLoading}
							sx={{
								'& .MuiDataGrid-columnHeaderTitleContainer': {
									fontSize: '13px',
								},
								'& .MuiDataGrid-cell': {
									fontSize: '13px !important',
									borderRight:
										theme.palette.mode === 'dark'
											? '1px solid rgba(81, 81, 81, 1)'
											: '1px solid rgba(224, 224, 224, 1)',
								},
							}}
						/>
					</div>
				</DialogContent>
				<DialogActions>
					<Button
						variant="contained"
						color="primary"
						onClick={prevResultClose}
					>
						확인
					</Button>
				</DialogActions>
			</Dialog>
		</>
	)
}

export default PrevResult
