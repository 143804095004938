import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import {
	AppBar,
	Box,
	IconButton,
	Paper,
	styled,
	Table,
	TableBody,
	TableCell,
	TableRow,
	Toolbar,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material'
import TransitionComponent from 'components/TransitionComponent'
import React, { useEffect } from 'react'
import { currencyFormat, replaceBrTag } from 'util/stringUtil'
import CloseIcon from '@mui/icons-material/Close'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	backgroundColor: '#DCE5FB',
	color: '#000',
}))

const StyledTable = styled(Table)(({ theme }) => ({
	borderCollapse: 'collapse',
	border: '1px solid #ccc',
	'& th, & td': {
		border: '1px solid #ccc',
	},
}))

const TestInfo = ({
	testInfoItem,
	referenceMList,
	referenceFList,
	referenceCList,
	testInfoDialog,
	testInfoClose,
}: {
	testInfoItem: any
	referenceMList: any[]
	referenceFList: any[]
	referenceCList: any[]
	testInfoDialog: boolean
	testInfoClose: () => void
}) => {
	const theme = useTheme()
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

	useEffect(() => {
		console.log(testInfoItem)
	}, [])
	return (
		<Dialog
			open={testInfoDialog}
			TransitionComponent={TransitionComponent}
			keepMounted
			fullScreen={fullScreen}
			onClose={testInfoClose}
			maxWidth="sm"
			fullWidth
			aria-describedby="검사정보 팝업"
		>
			<AppBar
				sx={{ position: 'sticky' }}
				className="noPrint"
			>
				<Toolbar>
					<Typography
						sx={{ ml: 2, flex: 1 }}
						variant="h6"
						component="p"
					>
						{testInfoItem.DisplayName}
					</Typography>
					<IconButton
						edge="end"
						color="inherit"
						aria-label="close"
						onClick={testInfoClose}
					>
						<CloseIcon />
					</IconButton>
				</Toolbar>
			</AppBar>
			<DialogContent>
				<Box sx={{ p: 0, overflowX: 'auto' }}>
					<StyledTable
						size="small"
						sx={{ width: 550 }}
					>
						<colgroup>
							<col style={{ width: '25%' }} />
							<col style={{ width: '25%' }} />
							<col style={{ width: '25%' }} />
							<col style={{ width: '25%' }} />
						</colgroup>
						<TableBody>
							<TableRow>
								<StyledTableCell sx={{ textAlign: 'center' }}>검사코드</StyledTableCell>
								<StyledTableCell sx={{ textAlign: 'center' }}>분류번호</StyledTableCell>
								<StyledTableCell sx={{ textAlign: 'center' }}>검사일</StyledTableCell>
								<StyledTableCell sx={{ textAlign: 'center' }}>검사방법</StyledTableCell>
							</TableRow>
							<TableRow>
								<TableCell
									rowSpan={3}
									sx={{ textAlign: 'center' }}
								>
									{testInfoItem?.TestCode}
								</TableCell>
								<TableCell sx={{ textAlign: 'center', height: 70 }}>
									{testInfoItem?.InsuranceClassChar}
								</TableCell>
								<TableCell sx={{ textAlign: 'center', height: 70 }}>
									{testInfoItem?.TestTerm}
								</TableCell>
								<TableCell sx={{ textAlign: 'center', height: 70 }}>
									{testInfoItem?.MethodName}
								</TableCell>
							</TableRow>
							<TableRow>
								<StyledTableCell sx={{ textAlign: 'center' }}>보험코드</StyledTableCell>
								<StyledTableCell sx={{ textAlign: 'center' }}>소요일</StyledTableCell>
								<StyledTableCell sx={{ textAlign: 'center' }}>검사수가</StyledTableCell>
							</TableRow>
							<TableRow>
								<TableCell sx={{ textAlign: 'center', height: 70 }}>
									{
										/* testInfoItem?.InsuranceCode에 쉼표가 1개 이상이면 쉼표 단위로 개행한다. */
										(testInfoItem?.InsuranceCode || '').split(',').length > 1
											? (testInfoItem?.InsuranceCode || '')
													.split(',')
													.map((item: string, index: number) => {
														return (
															<React.Fragment key={index}>
																{item}
																<br />
															</React.Fragment>
														)
													})
											: testInfoItem?.InsuranceCode
									}
								</TableCell>
								<TableCell sx={{ textAlign: 'center', height: 70 }}>
									{testInfoItem.TestNeccessaryDay
										? `${testInfoItem.TestNeccessaryDay}`
										: ''}
								</TableCell>
								<TableCell sx={{ textAlign: 'center', height: 70 }}>
									{testInfoItem?.InsurancePrice || testInfoItem?.UnInsurancePrice ? (
										<>
											{currencyFormat(
												(testInfoItem.InsurancePrice || 0) +
													(testInfoItem.UnInsurancePrice || 0)
											)}{' '}
											<br />
											<span style={{ fontSize: '10px' }}>
												* 질가산료를 포함하지 않습니다.
											</span>
										</>
									) : (
										''
									)}
								</TableCell>
							</TableRow>
							<TableRow>
								<StyledTableCell>검체종류</StyledTableCell>
								<TableCell colSpan={2}>
									{testInfoItem.SpecimenName && (
										<Typography variant="body2">{testInfoItem.SpecimenName}</Typography>
									)}
								</TableCell>
								<TableCell
									colSpan={2}
									rowSpan={5}
								>
									{testInfoItem.ContainerPhoto && (
										<Paper
											variant="outlined"
											sx={{ padding: '0.5rem' }}
										>
											<img
												src={`data:image/jpeg;base64,${testInfoItem.ContainerPhoto}`}
												alt="검사이미지"
												style={{
													maxWidth: '100%',
													display: 'block',
													marginLeft: 'auto',
													marginRight: 'auto',
												}}
											/>
											{testInfoItem.ContainerName && (
												<div
													style={{
														border: '1px solid #4B7BEC',
														padding: '0.5rem',
														textAlign: 'center',
														borderRadius: '5px',
													}}
												>
													{testInfoItem.ContainerName}
												</div>
											)}
										</Paper>
									)}
								</TableCell>
							</TableRow>
							<TableRow>
								<StyledTableCell>검체량</StyledTableCell>
								<TableCell colSpan={2}>
									{testInfoItem.VolumeOptimum && (
										<Typography variant="body2">{testInfoItem.VolumeOptimum}</Typography>
									)}
								</TableCell>
							</TableRow>
							<TableRow>
								<StyledTableCell>검체보관</StyledTableCell>
								<TableCell colSpan={2}>
									{testInfoItem.KeepMethod && (
										<Typography variant="body2">{testInfoItem.KeepMethod}</Typography>
									)}
								</TableCell>
							</TableRow>
							<TableRow>
								<StyledTableCell>검체안정성</StyledTableCell>
								<TableCell colSpan={2}>
									{testInfoItem.SpecimenStability && (
										<Typography variant="body2">
											{testInfoItem.SpecimenStability}
										</Typography>
									)}
								</TableCell>
							</TableRow>
							<TableRow>
								<StyledTableCell>참고치</StyledTableCell>
								<TableCell colSpan={2}>
									<div
										style={{ minHeight: '30px', maxHeight: '150px', overflowY: 'auto' }}
									>
										{referenceMList.map((item: any, index: number) => {
											return (
												<React.Fragment key={index}>
													{index === 0 && (
														<Typography
															variant="body2"
															sx={{ mb: 0.5 }}
														>
															남성
														</Typography>
													)}
													<Typography
														variant="body2"
														sx={{ lineHeight: '1.5' }}
													>
														<span style={{ marginRight: '5px' }}>
															{item.StartAge} ~ {item.EndAge}세
														</span>
														<span
															dangerouslySetInnerHTML={{
																__html: replaceBrTag(item.ReferenceTextDisplay),
															}}
														></span>
														<span style={{ marginLeft: '5px' }}>({item.ReferenceUnit})</span>
													</Typography>
												</React.Fragment>
											)
										})}
										{referenceFList.map((item: any, index: number) => {
											return (
												<React.Fragment key={index}>
													{index === 0 && (
														<Typography
															variant="body2"
															sx={{ mt: 0.5, mb: 0.5 }}
														>
															여성
														</Typography>
													)}
													<Typography
														variant="body2"
														sx={{ lineHeight: '1.5' }}
													>
														<span style={{ marginRight: '5px' }}>
															{item.StartAge} ~ {item.EndAge}세
														</span>
														<span
															dangerouslySetInnerHTML={{
																__html: replaceBrTag(item.ReferenceTextDisplay),
															}}
														></span>
														<span style={{ marginLeft: '5px' }}>({item.ReferenceUnit})</span>
													</Typography>
												</React.Fragment>
											)
										})}
										{referenceCList.map((item: any, index: number) => {
											return (
												<React.Fragment key={index}>
													{index === 0 && (
														<Typography
															variant="body2"
															sx={{ mt: 0.5, mb: 0.5 }}
														>
															공통
														</Typography>
													)}
													<Typography
														variant="body2"
														sx={{ lineHeight: '1.5' }}
													>
														<span style={{ marginRight: '5px' }}>
															{item.StartAge} ~ {item.EndAge}세
														</span>
														<span
															dangerouslySetInnerHTML={{
																__html: replaceBrTag(item.ReferenceTextDisplay),
															}}
														></span>
														<span style={{ marginLeft: '5px' }}>({item.ReferenceUnit})</span>
													</Typography>
												</React.Fragment>
											)
										})}
									</div>
								</TableCell>
							</TableRow>
							<TableRow>
								<StyledTableCell>유의사항</StyledTableCell>
								<TableCell colSpan={4}>
									{testInfoItem.Notice && (
										<Typography variant="body2">
											{testInfoItem.Notice.split('\r\n').map(
												(line: any, index: number) => {
													return (
														<React.Fragment key={index}>
															{line}
															<br />
														</React.Fragment>
													)
												}
											)}{' '}
										</Typography>
									)}
								</TableCell>
							</TableRow>
							<TableRow>
								<StyledTableCell>필수서류</StyledTableCell>
								<TableCell colSpan={4}>
									{testInfoItem.AcademicNotes && (
										<Typography variant="body2">{testInfoItem.AcademicNotes}</Typography>
									)}
								</TableCell>
							</TableRow>
							<TableRow>
								<StyledTableCell>임상적 의의</StyledTableCell>
								<TableCell colSpan={4}>
									{testInfoItem.ClinicalSigni && (
										<Typography variant="body2">
											{testInfoItem.ClinicalSigni.split('\r\n').map(
												(line: any, index: number) => {
													return (
														<React.Fragment key={index}>
															{line}
															<br />
														</React.Fragment>
													)
												}
											)}{' '}
										</Typography>
									)}
									{testInfoItem?.HightSigni && (
										<Typography
											variant="body2"
											dangerouslySetInnerHTML={{
												__html: 'High<br/>' + replaceBrTag(testInfoItem?.HightSigni),
											}}
										></Typography>
									)}
									{testInfoItem?.LowSigni && (
										<Typography
											variant="body2"
											dangerouslySetInnerHTML={{
												__html: 'Low<br/>' + replaceBrTag(testInfoItem?.LowSigni),
											}}
										></Typography>
									)}
								</TableCell>
							</TableRow>
						</TableBody>
					</StyledTable>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button
					variant="contained"
					color="primary"
					onClick={testInfoClose}
				>
					확인
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default TestInfo
